import $ from 'jquery';
import 'what-input';

window.$ = $;
window.jQuery = $;

import './lib/foundation-explicit-pieces';

Foundation.Reveal.defaults.animationIn = 'fade-in fast';
Foundation.Reveal.defaults.animationOut = 'fade-out fast';

$(document).foundation();

$(function() {
    var $linkMoznosti = $('#link-moznosti');
    var $linkSluzby = $('#link-sluzby');

    $(document).on('open.zf.reveal', '#subnav-moznosti[data-reveal]', function () {
        $linkMoznosti.addClass('is-active');
    });
    $(document).on('closed.zf.reveal', '#subnav-moznosti[data-reveal]', function () {
        $linkMoznosti.removeClass('is-active');
    });

    $(document).on('open.zf.reveal', '#subnav-sluzby[data-reveal]', function () {
        $linkSluzby.addClass('is-active');
    });
    $(document).on('closed.zf.reveal', '#subnav-sluzby[data-reveal]', function () {
        $linkSluzby.removeClass('is-active');
    });

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if(['large', 'xlarge', 'xxlarge'].includes(oldSize) && ['small', 'medium'].includes(newSize) ||
            ['large', 'xlarge', 'xxlarge'].includes(newSize) && ['small', 'medium'].includes(oldSize)) {
            $('.reveal').foundation('close');
        }
    });

    $('.arrow-scroll').on('click', function() {
        var $container = $(this).closest('.header, .header-banner, .section');

        $('html,body').animate({
            scrollTop: $container.outerHeight() + $container.offset().top
        }, 400)
    });
});
